import React from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Card = (props) => {
    var image;
    if (props.imagedata !== null) {
        image = <GatsbyImage image={props.imagedata} alt={props.title} />;
    } else {
        image = <StaticImage src="../images/hero-image.png" alt={props.title} />;
    }
    let wscardtags = [];
    let lrcardtags = [];
    if (props.kstags) {
        props.kstags.map((ks) => {
            wscardtags.push(ks.project_tag);
        });
    }
    // if (props.subtags) {
    //     props.subtags.map((sub) => {
    //         wscardtags.push(sub.subject_tag);
    //     });
    // }
    if (props.lrkstags) {
        props.lrkstags.map((ks) => {
            lrcardtags.push(ks.project_tag);
        });
    }
    // if (props.lrsubtags) {
    //     props.lrsubtags.map((sub) => {
    //         lrcardtags.push(sub.subject_tag);
    //     });
    // }
    return (
        <div className={props.class}>
            <div className="card-image">{image}</div>
            <div className="card-tags">
                {wscardtags
                    ? wscardtags.map((cardtag) => <div className="card-tags--tag">{cardtag}</div>)
                    : null}
            </div>
            <div className="card-content p-6" style={props.bgcolor}>
                {props.type ? <span className="type">{props.type}</span> : null}
                {lrcardtags
                    ? lrcardtags.map((cardtag) => <span className="tags">{cardtag}</span>)
                    : null}
                <h2 className="card-content--title">{props.title}</h2>
                {props.duration ? <span>Duration: {props.duration}</span> : null}
                {props.leader ? <span>Leader: {props.leader}</span> : null}
                {props.excerpt ? <p>{props.excerpt}</p> : null}
                <Link to={props.link}>{props.anchor}</Link>
            </div>
        </div>
    );
};

export default Card;
